/* You can add global styles to this file, and also import other style files */
/* Importing Bootstrap SCSS file. */
.ecommerce button::after {
  content: "\f0374";
  display: block;
  font-family: "Material Design Icons";
  font-size: 16px;
  position: absolute;
  right: 20px;
  font-weight: 500;
  top: 30%;
  background-image: none;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.accordion-button:not(.collapsed)::after {
  background-image: none;
}